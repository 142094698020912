interface battlereport {
    id: number,
    linkId: number,
    date: string,
    turn: string,
    text: string,
    src: string,
}
const battlereports: battlereport[] = [
    {
        id: 1,
        linkId: 1,
        date: new Date('2020-10-09').toLocaleDateString("en-CA"),
        turn: 'Deployment.',
        text: `Battle report 2020-10-09: 3-player Free For All - 1500pts - King of the Hill4*4 feet table, deployment zone 12inch board corners, objective (hill marked with statue) 12inch from the 4th (empty) corner. Winner is the player controlling the objective at the end of 5th battleround. Ralf : Sylvaneth Gijs : Chaos Everchosen Jim : Kharadron Overlords Pictures are at end of phase Set Up:The Sylvaneth deployed their melee Kurnoth hunters with Durthu aimed at the objective, whilst the forest worth of dryads were set up to shield the bow wielding Hunters at the rear. Archeon was set up in the zone furthest from the objective, eager to swoop forward with varenguard, slaughterbeast and warriors flanking him close-by. The Kharadronss loaded everyone on foot in the Ironclad, with the balloon dwarfs deployed in tight formation around it.`,
        src: 'deployment.jpg',
    },
    {
        id: 2,
        linkId: 1,
        date: new Date('2020-10-09').toLocaleDateString("en-CA"),
        turn: 'Round one: Sylvaneth -> Everchosen -> Kharadron.',
        text: `The Sylvaneth split in two chunks, the Dryads heading to intercept the forces of chaos, whilst the kurnoth hunters and Durthu made a run (or more a slow jog) straight towards the objective. Archeon and most of his forces carefully moved towards the objective, maneuvering around the tower blocking their path to try and prevent the Kharadrons from drawing a clear line of sight. Only a single group of warriors advanced menacingly towards the dwarf forces. The Kharadron endrinriggers positioned themselves to get a bead on the Sylvaneth with their heavy weapons, causing only minor damage to durthu. Whilst the Ironclad slowly scraped its overloaded hull into a firing position and subsequently blasting (together with its garrison) the approaching group of chaos warriors to meet their gods.`,
        src: 'EOT_1.jpg',
    },
    {
        id: 3,
        linkId: 1,
        date: new Date('2020-10-09').toLocaleDateString("en-CA"),
        turn: 'Round two: Sylvaneth -> Kharadron -> Everchosen.',
        text: `The Sylvaneth continued the dryads partially towards the chaos flank, taking partial position in the awakened wyldwoods in the center of the board. Meanwhile Durthu and his accompanying Kurnoth hunters continued their slow jog around the blocking tower, closing in on the Kharadrons and coming close to controlling the objective. The Kharadron, seeing the looming shadow of Archeon in the distance, decide on carefully advancing toward the objective as far away from the forces of Chaos as they can. Though in their caution they fail to cause any damage with their ranged fire this turn. The Everchosen surged forward towards the Sylvaneth lines, slamming into the dryads with Archeon, the slaughterbeast and the Varanguard, butchering all but a single one.`,
        src: 'EOT_2.jpg',
    },
    {
        id: 4,
        linkId: 1,
        date: new Date('2020-10-09').toLocaleDateString("en-CA"),
        turn: 'Round three: Sylvaneth -> Everchosen -> Kharadron.',
        text: `With Archeon and the Varanguard bearing down on them, the Sylvaneth picked up the pace and ran to the objective to take up a defensive position, securing it from the approaching Kharadrons. Archeon and the slaughterbeast crashed into and subsequently squashed the Kurnoth archers in the sylvaneth rearguard, clearing the way for a dash towards the objective next turn. With the Sylvaneth swarming over the objective, the Kharadron decided to loose some weight by deploying a squad of arkhanouts and use the Ironclads Fly-High to reposition everyone except the unfortunate arkhanouts at the other side of the board. After touching down they let loose with every available weapon and managed to take down two varanguard and dealing severe damage to Archeon.`,
        src: 'EOT_3.jpg',
    },
    {
        id: 5,
        linkId: 1,
        date: new Date('2020-10-09').toLocaleDateString("en-CA"),
        turn: 'Round four: Sylvaneth -> Kharadron -> Everchosen.',
        text: `Seeing Archeon weakened, the Sylvaneth charged him with the Kurnoth hunters, managing to lay low the everchosen of chaos by the methodical relentless strikes of their weapons. Realising the objective was now up for grabs, the Kharadrons took a firm grip of the Ironclad as it took of into the sky once more. Touching down right next to the objective. Subsequently managing to take down Durthu with concentrated firepower. Having lost their Leader, the remaining forces of chaos tried to escape from the menacing horde of Kurnoth hunters by maneuvering around the other side of the tower ruins.`,
        src: 'EOT_4.jpg',
    },
    {
        id: 6,
        linkId: 1,
        date: new Date('2020-10-09').toLocaleDateString("en-CA"),
        turn: 'Round five: Sylvaneth -> Everchosen -> Kharadron.',
        text: `Seeing the Kharadrons landing on the objective in force, the Sylvaneth ran as fast as they could towards the objective, but they were to slow to reach the dwarven forces in time. The remnants of the chaos army completely ignored the Sylvaneth as they to ran at full pace towards the objective. Managing to claim the objective by weight of numbers, for now. Confident in their positioning, the Kharadrons deployed their thunderers from the Ironclad to regain control of the objective. The subsequent fire was supremely underwhelming, with the Silvaneth losing a single Kurnoth hunter and no casualties on the chaos side. MVP of this rounds shooting goes to the Kharadron general, who managed to fumble with his Phosphorite Bomblets and drop them fizzling in the mud. (rolling 2+ is difficult okay!)`,
        src: 'EOT_5.jpg',
    },
    {
        id: 7,
        linkId: 1,
        date: new Date('2020-10-09').toLocaleDateString("en-CA"),
        turn: 'End',
        text: `At the end of the game, the Kharadron were in control of the objective, thereby securing their victory.`,
        src: 'EOT_1_Kharadron.jpg',
    },
    {
        id: 8,
        linkId: 2,
        date: new Date('2020-09-25').toLocaleDateString("en-CA"),
        turn: 'Round 1:',
        text: `Old`,
        src: 'EOT_1.jpg',
    },
    {
        id: 9,
        linkId: 2,
        date: new Date('2020-09-25').toLocaleDateString("en-CA"),
        turn: 'Round 2:',
        text: `Old`,
        src: 'EOT_2.jpg',
    },
    {
        id: 10,
        linkId: 2,
        date: new Date('2020-09-25').toLocaleDateString("en-CA"),
        turn: 'Round 3:',
        text: `Old`,
        src: 'EOT_3.jpg',
    },
    {
        id: 11,
        linkId: 3,
        date: new Date('2021-04-30').toLocaleDateString("en-CA"),
        turn: 'Round 1: Cities of Sigmar',
        text: `The Duardin moved up to contest the objectives and getting ready to fire.`,
        src: 'img-1.jpg',
    },
    {
        id: 12,
        linkId: 3,
        date: new Date('2021-04-30').toLocaleDateString("en-CA"),
        turn: 'Round 1: Nighthaunt/Kharadron',
        text: `The nighthaunt moved closer to get ready for a massive charge, meanwhile the kharadron flew to max range and started barraging Gortek with massive fire.`,
        src: 'img-2.jpg',
    },
    {
        id: 13,
        linkId: 3,
        date: new Date('2021-04-30').toLocaleDateString("en-CA"),
        turn: 'Round 2: Nighthaunt/Kharadron',
        text: `The kharadron opent fire again on to Gortek this time finishing him off. This gave the nighthaunt enaugh confidence to charge in to the line and started slaughtering Duardin.`,
        src: 'img-3.jpg',
    },
    {
        id: 14,
        linkId: 3,
        date: new Date('2021-04-30').toLocaleDateString("en-CA"),
        turn: 'Round 2: Cities of Sigmar',
        text: `The Duardin scrambeld to get a defence going but they knew it was doomed at this point.`,
        src: 'img-4.jpg',
    },
    {
        id: 15,
        linkId: 3,
        date: new Date('2021-04-30').toLocaleDateString("en-CA"),
        turn: 'Round 3: Nighthaunt/Kharadron',
        text: `The hammerers and ironbreakers tried to make something happen but that failed aswell and it was a short surrender after.`,
        src: 'img-5.jpg',
    },
    {
        id: 16,
        linkId: 3,
        date: new Date('2021-05-01').toLocaleDateString("en-CA"),
        turn: 'Round 1: Maggotkin of Nurgle',
        text: `Primary objective most right. nurgle couldn't reach the objective.`,
        src: 'img-1.jpg',
    },
    {
        id: 17,
        linkId: 4,
        date: new Date('2021-05-01').toLocaleDateString("en-CA"),
        turn: 'Round 1: Sons of Behemat',
        text: `All the gargants moved in range of the objectives and created a big advantage.`,
        src: 'img-2.jpg',
    },
    {
        id: 18,
        linkId: 4,
        date: new Date('2021-05-01').toLocaleDateString("en-CA"),
        turn: 'Round 2: Maggotkin of Nurgle',
        text: `Nurgle moved up closer to the objectives but couldn't capture them back and fell even futher behind.`,
        src: 'img-3.jpg',
    },
    {
        id: 19,
        linkId: 4,
        date: new Date('2021-05-01').toLocaleDateString("en-CA"),
        turn: 'Round 2: Sons of Behemat',
        text: `Triple gargants charged the plague bearers for some good damage. while holding most of the objectives.`,
        src: 'img-4.jpg',
    },
    {
        id: 20,
        linkId: 4,
        date: new Date('2021-05-01').toLocaleDateString("en-CA"),
        turn: 'Round 3: Maggotkin of Nurgle',
        text: `On the right the Exalted Greater Daemon of Nurgle challenged the Mega-Gargant to a fight. But failed to swing his sword because hes so fat and misses almost every attack (7 attacks thanks to all the buffs).`,
        src: 'img-5.jpg',
    },
    {
        id: 21,
        linkId: 4,
        date: new Date('2021-05-01').toLocaleDateString("en-CA"),
        turn: 'Round 3: Sons of Behemat',
        text: `The gargants on the left repositioned for a charge at the blightkings and killed them. meanwhile the blightkings and beasts of nurgle finished of the Mega-Gargant on the right.`,
        src: 'img-6.jpg',
    },
    {
        id: 22,
        linkId: 4,
        date: new Date('2021-05-01').toLocaleDateString("en-CA"),
        turn: 'Round 4: Maggotkin of Nurgle',
        text: `All Nurgle forces moved to control the center and try to fight off the Mega-Gargant. While summoning mulitple small groups of plague bearers to capture the objectives.`,
        src: 'img-7.jpg',
    },
    {
        id: 23,
        linkId: 4,
        date: new Date('2021-05-01').toLocaleDateString("en-CA"),
        turn: 'Round 4: Sons of Behemat',
        text: `The Gargants realizing that they were to far ahead didn't go for any risky moves and re-captured the left objective to stay in control of most of the objectives.`,
        src: 'img-8.jpg',
    },
    {
        id: 24,
        linkId: 4,
        date: new Date('2021-05-01').toLocaleDateString("en-CA"),
        turn: 'Round 5:',
        text: `The Nurgle forces realizing they could't comeback from such a big deficit surrenderd and the Sons of behemat win.`,
        src: 'img-9.jpg',
    },
    {
        id: 25,
        linkId: 5,
        date: new Date('2021-05-28').toLocaleDateString("en-CA"),
        turn: 'Deployment',
        text: ``,
        src: 'img-1.jpg',
    },
    {
        id: 26,
        linkId: 5,
        date: new Date('2021-05-28').toLocaleDateString("en-CA"),
        turn: 'Round 1:',
        text: `The slaaneshie forces move back to safer positions and wait for the first star to crash down on the battlefield.`,
        src: 'img-2.jpg',
    },
    {
        id: 27,
        linkId: 5,
        date: new Date('2021-05-28').toLocaleDateString("en-CA"),
        turn: 'Round 1:',
        text: `All Nighthaunt move to the flanks to crush the weak cavelry, meanwhile the kharadron overlords moved in position and obliterated a keeper of secrets`,
        src: 'img-3.jpg',
    },
    {
        id: 28,
        linkId: 5,
        date: new Date('2021-05-28').toLocaleDateString("en-CA"),
        turn: 'Round 2:',
        text: `The first Star crashed on the right side of the board. The Nighthaunt charged at the slaanesh cavalry and destroyed the left flank, and tried to destroy the right flank but failed doing so. This time the Kharadron Went for Shalaxi Helbane and obliterated her to.`,
        src: 'img-4.jpg',
    },
    {
        id: 29,
        linkId: 5,
        date: new Date('2021-05-28').toLocaleDateString("en-CA"),
        turn: 'Round 2:',
        text: `Glutos Realizing he had to take this into his own hands rushed up to meat a horde of spirit hosts and crushed them.`,
        src: 'img-5.jpg',
    },
    {
        id: 30,
        linkId: 5,
        date: new Date('2021-05-28').toLocaleDateString("en-CA"),
        turn: 'Round 3:',
        text: `The second star landed close to Sigvald and his retinue and the third star landed all the way next to the nighthaunt on the left. Meanwhile Glutos prepeard for another charge to free the wavering hellstriders on the right.`,
        src: 'img-6.jpg',
    },
    {
        id: 31,
        linkId: 5,
        date: new Date('2021-05-28').toLocaleDateString("en-CA"),
        turn: 'Round 3:',
        text: `The kharadron tried to disintegrate Glutos but failed to do so. Now realizing the the Nighthaunt needed a desperate battle against the hellstriders and Glutos needed support for control over the stars power. a Close battle unfolds but the allies failed to defeat the slaaneshie moratals and so slaanesh remaind in control of the stars power. Realizing the slaanesh mortals were ready to counter attack there wouldn't be enough time left so the Allies had to withdraw from the battlefield. Giving Slaanesh the overal victory after a close battle.`,
        src: 'img-7.jpg',
    },
    {
        id: 32,
        linkId: 6,
        date: new Date('2021-05-21').toLocaleDateString("en-CA"),
        turn: 'First 2 turns:',
        text: `Sadly I forgot to take alot of picture so i only have a few. That said Mega-Gargants moved in position of the objectives on the middle and top position leaving the bottem one uncaptured. In otherwords they are planning a devastating charge to capture the objectives on the side of the slaves to darkness force.`,
        src: 'img-1.jpg',
    },
    {
        id: 33,
        linkId: 6,
        date: new Date('2021-05-21').toLocaleDateString("en-CA"),
        turn: 'Second 2 turns:',
        text: `The gargants were able to charge strait into the varangaurd line but were not expecting to recieve a beeting afterwards that cost a little to much. The daemon prince and chaos lord counter charged to reinforce the varangaurd and tried to kill the mega-gargant. But he survived on his last breath.`,
        src: 'img-2.jpg',
    },
    {
        id: 34,
        linkId: 6,
        date: new Date('2021-05-21').toLocaleDateString("en-CA"),
        turn: 'Last turns:',
        text: `After a massive charge from the varangaurd on some the gargaunt force in that was stationd in the center and killed them but at the cost of there own lives, the mega-gargant relized he was all alone and could't fight all these different chaos worshippers alone. This meant that almost every magical source became chaos powered infused with chaos magic and they succeeded in completing there mission.`,
        src: 'img-3.jpg',
    },
    {
        id: 35,
        linkId: 7,
        date: new Date('2021-05-22').toLocaleDateString("en-CA"),
        turn: 'After battle round 1:',
        text: `Sadly I forgot to take alot of picture so i only have a few. Nothing that intersesting happend at the start of the battle so the mega-gargants moved closer to the varangaurd. But the chaos sorcerer pooled a fast one and teleported a small force to the magical empowered stone on the far left and captured it. This move forced the gargants to full on attack relizing they were needed to capture the stones on the side of the Slaves to Darkness side. sadly they could't reach the line and received a devastating counter attack by the varanguard that crushed in the gargants line.`,
        src: 'img-1.jpg',
    },
    {
        id: 36,
        linkId: 7,
        date: new Date('2021-05-22').toLocaleDateString("en-CA"),
        turn: 'After battle round 3:',
        text: `After defeating many gargants the Slaves to Darkness were in control of all magical stones and started corrupting them with chaos magic. The Gargants got more desperate by the minute and tried to figh off the varanguard but they were to much infused with Khornes power by now and didn't even flinch at the sight of a mega-gargant.`,
        src: 'img-2.jpg',
    },
    {
        id: 37,
        linkId: 7,
        date: new Date('2021-05-22').toLocaleDateString("en-CA"),
        turn: 'After battle round 4:',
        text: `The ritual now completed by the Chaos worshippers forced the Gargants to retreat as they couldn't fight on all fronts and many the many chaos champions still remaning to fight them.`,
        src: 'img-3.jpg',
    },
    {
        id: 38,
        linkId: 8,
        date: new Date('2021-06-11').toLocaleDateString("en-CA"),
        turn: 'Deployment:',
        text: ``,
        src: 'img-1.jpg',
    },
    {
        id: 39,
        linkId: 8,
        date: new Date('2021-06-11').toLocaleDateString("en-CA"),
        turn: 'Round 1:',
        text: `Most of the armies all moved up closer to the White-dwarf. Ofcourse the Kharadron had to shoot to Slaves to Darkness before they were done.`,
        src: 'img-2.jpg',
    },
    {
        id: 40,
        linkId: 8,
        date: new Date('2021-06-11').toLocaleDateString("en-CA"),
        turn: 'Round 2:',
        text: `The Slaves to Darkness moved to face the Kharadron because they were done with there cowardly missle fire. Sadly not all charges were made it to the Kharadron line. The Sons of behemat moved to the White-dwarf so they received the White-dwarfs gift. The Soulblight Gravelords were hesitent to face such massive Gargants and so decided it wasn't worth the risk to face them now. After some lucky missfortune for the Slaves to Darkness the Kharadron decided to fly away so they didn't have to face such a threat so for some reason they decided to go behind the Sons of Behemat. Which they prompted to shoot in the back this ofcourse pissed to Gargants of by not even the slighest so will see what comes of this.`,
        src: 'img-3.jpg',
    },
    {
        id: 41,
        linkId: 8,
        date: new Date('2021-06-11').toLocaleDateString("en-CA"),
        turn: 'Round 3:',
        text: `So yeh after that the Gargants weren't in the best of moods so they went after Kharadron and showed their crushing strength on the fragile boats. The Slaves to Darkness then moved in the get the White-dwarfs attention. Meanwhile the Kharadron in full panic mode scarmbeld to escape dissaster and left some of there troops to fend for them selves. And yes I forgot to take the last photo but the Kharadron were able to get the White-dwarfs attention at the end and there for succeded in the mission.`,
        src: 'img-4.jpg',
    },
    {
        id: 42,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Deployment:',
        text: ``,
        src: 'img-1.jpg',
    },
    {
        id: 43,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: `The Nurgle forces move up slowely not really showing anything yet with there defensive buffs active.`,
        src: 'img-2.jpg',
    },
    {
        id: 44,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: `The Kharadron again immediately go for the chaos army and start shelling them with missle fire. Meanwhile the gargants move up looking for a fight.`,
        src: 'img-3.jpg',
    },
    {
        id: 45,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: `The vampires are setting them selves up for a nice rear charge against the nurgle forces. With the rest off the army moving to off the gargants.`,
        src: 'img-4.jpg',
    },
    {
        id: 46,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Turn 2:',
        text: `The Kharadron get the initiative and continue to fire on the nurgle forces.`,
        src: 'img-5.jpg',
    },
    {
        id: 47,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Turn 2:',
        text: `The mega-gargant moves close enough towards the white-dwarf to receive a gift and throws a rock to finish of the Glottkin. the other gargants move closer for some expendable undead and throws some rocks at the zombies.`,
        src: 'img-6.jpg',
    },
    {
        id: 48,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Turn 2:',
        text: `The vampires replenish there loses and attack the forces of chaos and destruction. Finishing of the remaning nurgle command force. On the other side it went less well and the undead took major casualties.`,
        src: 'img-7.jpg',
    },
    {
        id: 49,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Turn 3:',
        text: `The gargants pushed there advantage on and destroyed the remeaning graveguard and dire wolves.`,
        src: 'img-8.jpg',
    },
    {
        id: 50,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Turn 3:',
        text: `The vampires now with no protection for there leaders retreated away from the gargants and continued fighting the forces of nurgle.`,
        src: 'img-9.jpg',
    },
    {
        id: 51,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Turn 3:',
        text: `Because the Kharadron are cowards they left nurgle front and started barraging the gargants from a distance.`,
        src: 'img-10.jpg',
    },
    {
        id: 52,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Turn 3:',
        text: `The remeaning nurgle forces moved to help face the bloodknights and tried defeating them.`,
        src: 'img-11.jpg',
    },
    {
        id: 53,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Turn 4:',
        text: `The gargants annoyed by the kharadron moved to smack the flying lunch boxes out of the skies. on the otherside of the board nurgle reinforced there lines with some small plageubearer assistances.`,
        src: 'img-12.jpg',
    },
    {
        id: 54,
        linkId: 9,
        date: new Date('2021-06-26').toLocaleDateString("en-CA"),
        turn: 'Turn 5:',
        text: `The vampires and there Deceitful tactics Secured the white-dwarfs attention to complete there mission.`,
        src: 'img-13.jpg',
    },
    {
        id: 55,
        linkId: 10,
        date: new Date('2021-07-10').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: `Right after everybody was set-up the overlords moved forward a bit and fired a few shots on the vampires.`,
        src: 'img-1.jpg',
    },
    {
        id: 56,
        linkId: 10,
        date: new Date('2021-07-10').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: `The vampires not to pleased moved closer to the Kharadron and charged the closest duardin.`,
        src: 'img-2.jpg',
    },
    {
        id: 57,
        linkId: 10,
        date: new Date('2021-07-10').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: `The forces of Nurgle moved up to the center hoping to secure it before anybody else.`,
        src: 'img-3.jpg',
    },
    {
        id: 58,
        linkId: 10,
        date: new Date('2021-07-10').toLocaleDateString("en-CA"),
        turn: 'Turn 2:',
        text: `All Kharadron forces retreated to a safer position and fired on both Vampires and Nurgle forces.`,
        src: 'img-4.jpg',
    },
    {
        id: 59,
        linkId: 10,
        date: new Date('2021-07-10').toLocaleDateString("en-CA"),
        turn: 'Turn 2:',
        text: `The vampire moved closer to the center and finished of the left behind Kharadron.`,
        src: 'img-5.jpg',
    },
    {
        id: 60,
        linkId: 10,
        date: new Date('2021-07-10').toLocaleDateString("en-CA"),
        turn: 'Turn 2:',
        text: `Nurgle moving in to the center claiming it for it self. Also attacked the weaker right flank of the vampires. And the exposed gunhowler of the Kharadron.`,
        src: 'img-6.jpg',
    },
    {
        id: 61,
        linkId: 10,
        date: new Date('2021-07-10').toLocaleDateString("en-CA"),
        turn: 'Turn 3:',
        text: `The Kharadron feeling like they were being left out barraged the nurgle forces with fire. the vampires finished of the Glottkin. Some blightkings wanted revenge for there loss attack the bloodknights.`,
        src: 'img-7.jpg',
    },
    {
        id: 62,
        linkId: 10,
        date: new Date('2021-07-10').toLocaleDateString("en-CA"),
        turn: 'Turn: 4',
        text: `The Blightkings finished of the bloodknights and secured a win because of the time limit.`,
        src: 'img-8.jpg',
    },
    {
        id: 63,
        linkId: 11,
        date: new Date('2021-07-11').toLocaleDateString("en-CA"),
        turn: 'Deployment:',
        text: ``,
        src: 'img-1.jpg',
    },
    {
        id: 64,
        linkId: 11,
        date: new Date('2021-07-11').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: `Chaos move up a little, mostly on the right flank.`,
        src: 'img-2.jpg',
    },
    {
        id: 65,
        linkId: 11,
        date: new Date('2021-07-11').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: `The gargants do the same but more to the center. (yes I forgot to take a picture, so here is Be'lakor pointing were they are moving towards to help you guys understand.)`,
        src: 'img-3.jpg',
    },
    {
        id: 66,
        linkId: 11,
        date: new Date('2021-07-11').toLocaleDateString("en-CA"),
        turn: 'Turn 2:',
        text: `Chaos seized the initiative and moved to destroy the right flank of the gargants. Both sides missing horribly at the same time.`,
        src: 'img-4.jpg',
    },
    {
        id: 67,
        linkId: 11,
        date: new Date('2021-07-11').toLocaleDateString("en-CA"),
        turn: 'Turn 2:',
        text: `The gargants getting ready to crush this turn and push for a advantage. But when everything was planned out Be'lakor pulled a fast one and marked one of the gargant mobs. They then started being manipulated by the Dark master. The gargant mob didn't listen to any orders to Be'lakors tricks. So wasting a valueble counter attack.`,
        src: 'img-5.jpg',
    },
    {
        id: 68,
        linkId: 11,
        date: new Date('2021-07-11').toLocaleDateString("en-CA"),
        turn: 'Turn 3:',
        text: `Chaos getting ready to make a decisive blow against the gargants charged in the varanguard in the center and charged with the knights to support Be'lakor and finished both targets off. Except for one gargant how held on bye a sliver of hope and survived the onslaught.`,
        src: 'img-6.jpg',
    },
    {
        id: 69,
        linkId: 11,
        date: new Date('2021-07-11').toLocaleDateString("en-CA"),
        turn: 'Turn 3:',
        text: `The Gatekeeper looked around and went if for some combat aswell destroying the varanguard instantly. The mob looking for a fight with the chaos warriors got stoped this time by the Khorne Daemon prince.`,
        src: 'img-7.jpg',
    },
    {
        id: 70,
        linkId: 11,
        date: new Date('2021-07-11').toLocaleDateString("en-CA"),
        turn: 'Turn 4:',
        text: `Chaos setting them selves up for a victory moved all forces into position. Be'lakor seeking more glory for himself went after the Gatekeeper, this didn't go so well as his massive ego got in the way of his attacks and failed almost all of them.`,
        src: 'img-8.jpg',
    },
    {
        id: 71,
        linkId: 11,
        date: new Date('2021-07-11').toLocaleDateString("en-CA"),
        turn: 'Turn 4:',
        text: `Be'lakor stop pointing and start fighting.`,
        src: 'img-9.jpg',
    },
    {
        id: 72,
        linkId: 11,
        date: new Date('2021-07-11').toLocaleDateString("en-CA"),
        turn: 'Turn 4:',
        text: `The behemat's seeing no hope went for some personal glory and started killing the lonely warriors.`,
        src: 'img-10.jpg',
    },
    {
        id: 73,
        linkId: 11,
        date: new Date('2021-07-11').toLocaleDateString("en-CA"),
        turn: 'Turn 5:',
        text: `Still going after the last few chaos forces the sorcerer got his head smashed by the Gatekeeper.`,
        src: 'img-11.jpg',
    },
    {
        id: 74,
        linkId: 11,
        date: new Date('2021-07-11').toLocaleDateString("en-CA"),
        turn: 'Turn 5:',
        text: `Be'lakor still not done with fighting together with the other Prince went after the Gatekeeper again, but to no avail. Still Be'lakor seized victory in the end.`,
        src: 'img-12.jpg',
    },
    {
        id: 75,
        linkId: 12,
        date: new Date('2021-09-01').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-1.jpg',
    },
    {
        id: 76,
        linkId: 12,
        date: new Date('2021-09-01').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-2.jpg',
    },
    {
        id: 77,
        linkId: 12,
        date: new Date('2021-09-01').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-3.jpg',
    },
    {
        id: 78,
        linkId: 12,
        date: new Date('2021-09-01').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-4.jpg',
    },
    {
        id: 79,
        linkId: 12,
        date: new Date('2021-09-01').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-5.jpg',
    },
    {
        id: 80,
        linkId: 12,
        date: new Date('2021-09-01').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-6.jpg',
    },
    {
        id: 81,
        linkId: 12,
        date: new Date('2021-09-01').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-7.jpg',
    },
    {
        id: 82,
        linkId: 13,
        date: new Date('2021-09-03').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-1.jpg',
    },
    {
        id: 83,
        linkId: 13,
        date: new Date('2021-09-03').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-2.jpg',
    },
    {
        id: 84,
        linkId: 13,
        date: new Date('2021-09-03').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-3.jpg',
    },
    {
        id: 85,
        linkId: 13,
        date: new Date('2021-09-03').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-4.jpg',
    },
    {
        id: 86,
        linkId: 13,
        date: new Date('2021-09-03').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-5.jpg',
    },
    {
        id: 87,
        linkId: 13,
        date: new Date('2021-09-03').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-6.jpg',
    },
    {
        id: 88,
        linkId: 14,
        date: new Date('2021-10-08').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-1.jpg',
    },
    {
        id: 89,
        linkId: 14,
        date: new Date('2021-10-08').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-2.jpg',
    },
    {
        id: 90,
        linkId: 14,
        date: new Date('2021-10-08').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-3.jpg',
    },
    {
        id: 91,
        linkId: 14,
        date: new Date('2021-10-08').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-4.jpg',
    },
    {
        id: 92,
        linkId: 14,
        date: new Date('2021-10-08').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-5.jpg',
    },
    {
        id: 93,
        linkId: 14,
        date: new Date('2021-10-08').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-6.jpg',
    },
    {
        id: 94,
        linkId: 14,
        date: new Date('2021-10-08').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-7.jpg',
    },
    {
        id: 95,
        linkId: 14,
        date: new Date('2021-10-08').toLocaleDateString("en-CA"),
        turn: 'Turn 1:',
        text: ``,
        src: 'img-8.jpg',
    },
]

export default battlereports